@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  margin: 0;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-shadow {
  text-shadow: -2px 2px 50px rgba(0,0,0,.5);
}

.custom-shadow2 {
  text-shadow: -2px 2px 40px rgba(0,0,0,.5);
}
